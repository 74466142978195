//import axios from 'axios'

export const loginMixin = {
  methods: {
    async livepanelDoLogin (user, pass) {

      return await new Promise(resolve => {
        const xhr = new XMLHttpRequest()
        const userEncoded = user.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&apos;')
        const passEncoded = pass.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&apos;')
        xhr.open('POST', 'https://bo-emea.opinat.com/index.php/ws/livepanel-soap/ws?ws=1', true)
        //xhr.open('POST', 'https://bo.opinat.com/index.php?r=livePanelSoap/ws&ws=1', true);

        const xhrRequest = '<?xml version="1.0" encoding="UTF-8"?>' +
          '<soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema">' +
          '<soap:Body><apiLogin><username>' + userEncoded + '</username><password>' + passEncoded + '</password></apiLogin></soap:Body></soap:Envelope>'
        xhr.setRequestHeader('Content-Type', 'text/xml; charset=utf-8')
        xhr.onload = async () => {
          if (xhr.status === 200) {
            resolve(xhr.responseXML.getElementsByTagName('ns1:apiLoginResponse')[0].textContent)
            const apiResponse = JSON.parse(xhr.responseXML.getElementsByTagName('ns1:apiLoginResponse')[0].textContent)
            console.log(apiResponse)
            await this.$store.dispatch('setLoginData', {
              user: user,
              pass: pass,
              token: apiResponse.sessionKey,
              client: apiResponse.client.toLowerCase(),
              time: new Date().getTime() + (20 * 60000)
            })
          } else {
            resolve(xhr.responseXML.getElementsByTagName('SOAP-ENV:Fault')[0].textContent)
            this.errorInput = false
            this.errorApi = true
          }
        }
        xhr.onerror = () => {
          resolve(undefined)
          this.$router.push('/')
        }
        xhr.send(xhrRequest)
      })
    },
    async livepanelLogin (user, pass) {
      // Check if login exists and token is still active
      const hasLoginData = !!this.$store.getters.getLoginData.token
      const expiredToken = this.$store.getters.getTokenTime < new Date().getTime()
      if (!hasLoginData || (hasLoginData && expiredToken)) {
        await this.livepanelDoLogin(user, pass)
      }
    }
  }
}
