import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueProgressBar from 'vue-progressbar'
import VueApexCharts from 'vue-apexcharts'
import BootstrapVue from 'bootstrap-vue'
import '@/assets/scss/custom.scss'

const VueProgressBaroptions = {
  color: '#0FEA70',
  failedColor: '#EF3D59',
  thickness: '2px',
  transition: {
    speed: '0.3s',
    opacity: '0.3s',
    termination: 300,
  }
}

Vue.use(VueProgressBar, VueProgressBaroptions)
Vue.use(VueApexCharts)
Vue.use(BootstrapVue)

Vue.component('apexchart', VueApexCharts)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


navigator.serviceWorker.getRegistrations().then(function(registrations) {
  for(let registration of registrations) {
    registration.unregister()
  } })
