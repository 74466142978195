import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      key: 'livepanel',
      paths: ['login']
    })
  ],
  // State
  state: {
    login: {
      user: null,
      pass: null,
      token: null,
      time: null,
      client: null
    },
    livepaneldata: {
      tab1chart1: {
        total_nps: 0,
        curyear_nps: 0,
        total_surveys: 0
      },
      tab1chart2: {
        total_nps: 0,
        curyear_nps: 0,
        total_surveys: 0
      },
      tab1chart3: {
        total_nps: 0,
        curyear_nps: 0,
        total_surveys: 0
      },
    }
  },

  // Mutations
  mutations: {
    SET_LOGIN(state, payload) {
      state.login = {
        user: payload.user,
        pass: payload.pass,
        token: payload.token,
        client: payload.client,
        time: payload.time
      }
    },
    SET_LOGOUT(state) {
      state.login = {
        user: null,
        pass: null,
        token: null,
        client: null,
        time: null
      }
      state.livepaneldata = []
    },
    SET_LIVEPANEL_DATA(state, payload) {
      state.livepaneldata[`tab${payload.tab}chart${payload.chart}`] = payload
    },
  },

  // Actions
  actions: {
    setLoginData({commit}, payload) {
      commit('SET_LOGIN', payload)
    },
    setLogout({commit}) {
      commit('SET_LOGOUT')
    },
    setLivepanelData({commit}, payload) {
      commit('SET_LIVEPANEL_DATA', payload)
    },
  },

  // Getters
  getters: {
    getLoginData: state => {
      return state.login
    },
    getTokenTime: state => {
      return state.login.time
    },
    getApiUrl: state => {
      let apiurl
      switch (state.login.client) {
        case 'iberostar':
          apiurl = 'livepanel-iberostar-soap'
          // apiurl = 'livePanelIberostarSoap'
          break
      }
      // return `https://bo.opinat.com/index.php?r=${apiurl}/ws&ws=1`
      return `https://bo-emea.opinat.com/index.php/ws/${apiurl}/ws?ws=1`
    }
  }
})
