<template>
  <b-row
    align-v="center"
    class="flex-row w-100"
  >
    <b-col
      cols="12"
      sm="5"
      md="4"
      lg="3"
      class="mx-auto"
    >
      <b-form @submit.prevent="doLogin">
        <b-form-group>
          <img
            src="@/assets/images/livepanels.png"
            srcset="@/assets/images/livepanels@2x.png 2x"
          >
        </b-form-group>
        <b-form-group>
          <label>Usuario</label>
          <input v-model="creds.user"
                 type="text"
                 class="form-control"
                 placeholder="Introduzca su ID de usuario"
                 autocorrect="off"
                 autocapitalize="none">
        </b-form-group>
        <b-form-group>
          <label>Contraseña</label>
          <input v-model="creds.pass"
                 type="password"
                 class="form-control"
                 placeholder="Introduzca su contraseña">
        </b-form-group>
        <b-form-group>
          <div
            v-if="errorApi"
            class="alert">Credenciales erróneas
          </div>
          <div
            v-if="errorInput"
            class="alert">Campos obligatorios
          </div>
          <b-button
            type="submit"
            variant="outline-primary"
          >
            Entrar
          </b-button>
        </b-form-group>
      </b-form>
    </b-col>
  </b-row>
</template>

<script>
  // @ is an alias to /src
  import { loginMixin } from '@/loginMixin'

  export default {
    name: 'home',
    mixins: [loginMixin],
    data() {
      return {
        errorApi: false,
        errorInput: false,
        creds: {
          user: null,
          pass: null
        }
      };
    },
    computed: {
      loginData () {
        return (this.$store.state.login.token) ? this.$store.state.login.token : null;
      }
    },
    methods: {
      doLogin() {
        this.$Progress.start();
        if (!this.creds.user || !this.creds.pass) {
          this.errorInput = true;
        } else {
          this.livepanelLogin(this.creds.user, this.creds.pass)
            .then(() => {
              this.$Progress.finish();
              if (this.$store.state.login.user && this.$store.state.login.pass && this.$store.state.login.token && this.$store.state.login.client) {
                console.log('Login done')
                // Router from client
                if (this.$store.state.login.client === 'iberostar') {
                  this.$router.push('/iberostar')
                }
              }
            });
        }
      }
    },
    mounted () {
      if (this.loginData) {
        this.creds.user = this.$store.state.login.user;
        this.creds.pass = this.$store.state.login.pass;
        this.doLogin()
      }
    }
  }
</script>
